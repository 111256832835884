// components/Header.js
import React from 'react';

const Header = () => {
  return (
    <header>
      <h1 className="qumi-title">Qumi - Word of the Day</h1>
    </header>
  );
}

export default Header;