// components/Key.js
import React from 'react';

const Key = ({ value, onClick, status }) => {
    const classNames = `key ${status}`;
    return (
      <button className={classNames} onClick={() => onClick(value)}>
        {value}
      </button>
    );
  };

export default Key;