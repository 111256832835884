// components/WordBlock.js
import React from 'react';

const WordBlock = ({ letter, status }) => {
    return (
      <div className={`word-block ${status}`}>
        {letter}
      </div>
    );
  }

export default WordBlock;
