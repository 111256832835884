import React from 'react';
import '../css/HowToPlayModal.css';
import WordRow from './WordRow';
import WordBlock from './WordBlock';

const HowToPlayModal = ({ isOpen, onRequestClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>How To Play</h2>
        <button onClick={onRequestClose} className="close-button">
          ×
        </button>
        <h3>Guess the word in 6 tries.</h3>
        <ul>
          <li>Each guess must be any combination of letters with a maximum of 7 letters.</li>
          <li>The color of the tiles will change to show how close your guess was to the word.</li>
        </ul>
        <h3>Examples:</h3>
      <div className="example-section">
        <p>Green means the letter is correct and in the correct position.</p>
        <WordRow word="axdxa" guess="apple" />
      </div>

      <div className="example-section">
        <p>Yellow means the letter is correct but in the wrong position.</p>
        <WordRow word="apple" guess="check" />
      </div>

      <div className="example-section">
        <p>Grey means the letter is incorrect.</p>
        <WordRow word="applt" guess="drone" />
      </div>
      </div>
    </div>
  );
};

export default HowToPlayModal;
