import React from 'react';
import html2canvas from 'html2canvas';
import '../css/CongratulationsModal.css';


const CongratulationsModal = ({ isOpen, wordInfo, onRequestClose, guesses }) => {
  const generateShareableElement = (content) => {
    const wrapper = document.createElement('div');
    wrapper.style.width = "1080px";  // Square layout for Instagram
    wrapper.style.height = "1080px";
    wrapper.style.fontFamily = "'Arial', sans-serif";
    wrapper.style.fontSize = "40px";  // Larger font for better readability
    wrapper.style.padding = "50px";  // Some breathing space
    wrapper.style.border = "5px solid #ddd";  // A bolder border for Instagram
    wrapper.style.backgroundColor = "#fafafa";  // A soft background
    wrapper.style.textAlign = "center";
    wrapper.style.display = "flex";  // Using flex to center content both horizontally and vertically
    wrapper.style.flexDirection = "column";  // Align children vertically
    wrapper.style.justifyContent = "center";  // Center children vertically
    wrapper.style.alignItems = "center";  // Center children horizontally
    wrapper.style.color = "#000";

    // Split content by newline and create individual divs
    content.split('\n').forEach(line => {
      const lineDiv = document.createElement('div');
      lineDiv.textContent = line;
      lineDiv.style.marginBottom = "20px";  // Space between each line for clarity
      wrapper.appendChild(lineDiv);
    });

    return wrapper;
  }


  const generateShareableContent = (guesses) => {
    return guesses.filter(guess => guess !== null).map(guess => {
      return guess.split('').map((char, index) => {
        if (char === wordInfo.word.charAt(index)) {
          return '🟩'; // Green block for correct position
        } else if (wordInfo.word.includes(char)) {
          return '🟨'; // Yellow block for correct character but wrong position
        } else {
          return '⬜'; // Grey block for incorrect character
        }
      }).join('');
    }).join('\n');
  };

  const shareGuesses = () => {
    const shareableContent = "https://qumiword.ca \nQumi Word of the Day " + guesses.filter(guess => guess !== null).length + "/6\n\n" + generateShareableContent(guesses);

    const textarea = document.createElement('textarea');
    textarea.value = shareableContent;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    alert('Guesses copied to clipboard!');
  }

  const handleShareClick = () => {
    const date = new Date();

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
    const year = date.getFullYear();

    const dateString = `${day}-${month}-${year}`;
    const shareableContent = dateString + "\n" + "https://qumiword.ca \nQumi Word of the Day " + guesses.filter(guess => guess !== null).length + "/6\n\n" + generateShareableContent(guesses);
    const elementToCapture = generateShareableElement(shareableContent);
    document.body.appendChild(elementToCapture);
    html2canvas(elementToCapture).then(canvas => {
      // Create an image
      const img = canvas.toDataURL("image/png");

      // Trigger a download
      const link = document.createElement("a");
      link.download = 'shared-content.png';
      link.href = img;
      link.click();

      // Remove the generated element after capturing
      document.body.removeChild(elementToCapture);
    });
  }




  return (
    isOpen ?
      <div className="modal">
        <div className="modal-content">
          <h2>Congratulations!</h2>
          <h3>Word of the day: {wordInfo.word}</h3>
          <p><strong>Definition:</strong> {wordInfo.definition}</p>
          <p><strong>Type:</strong> {wordInfo.type}</p>
          <div className="start-buttons-modal">
            <button onClick={shareGuesses} className="share-btn">Share</button>
            <button onClick={handleShareClick} className="download-picture-btn">Save Picture</button>
          </div>
        </div>
      </div>
      : null
  );
}

export default CongratulationsModal;
