// // components/WordRow.js
// import React from 'react';
// import WordBlock from './WordBlock';

// const WordRow = ({ guess, word, displayColor = true }) => {
//   const statuses = guess.split('').map((letter, index) => {
//     if (letter === word[index]) {
//       return 'correct-position'; // The letter is correct and in the correct position.
//     } else if (word.includes(letter)) {
//       return 'wrong-position'; // The letter is correct but in the wrong position.
//     } else {
//       return 'incorrect'; // The letter is not in the word.
//     }
//   });

//   return (
//     <div className="word-row">
//       {guess.split('').map((letter, index) => (
//         <WordBlock key={index} letter={letter} status={displayColor ? statuses[index] : ''} />
//       ))}
//     </div>
//   );
// }

// export default WordRow;

import React from 'react';
import WordBlock from './WordBlock';

const WordRow = ({ guess, word, displayColor = true }) => {
  // Create an object to store the counts for each letter in the word
  const letterCounts = {};
  for (let letter of word) {
    if (letterCounts[letter]) {
      letterCounts[letter]++;
    } else {
      letterCounts[letter] = 1;
    }
  }

  const statuses = guess.split('').map((letter, index) => {
    if (letter === word[index]) {
      letterCounts[letter]--;
      return 'correct-position'; // The letter is correct and in the correct position.
    } else if (letterCounts[letter] && letterCounts[letter] > 0) {
      letterCounts[letter]--;
      return 'wrong-position'; // The letter is correct but in the wrong position.
    } else {
      return 'incorrect'; // The letter is not in the word.
    }
  });

  return (
    <div className="word-row">
      {guess.split('').map((letter, index) => (
        <WordBlock key={index} letter={letter} status={displayColor ? statuses[index] : ''} />
      ))}
    </div>
  );
}

export default WordRow;

