// components/Keyboard.js
import React from 'react';
import Key from './Key';
import '../css/Keyboard.css';

// const Keyboard = ({ onKeyPress }) => {
//   const rows = [
//     ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
//     ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
//     ['Z', 'X', 'C', 'V', 'B', 'N', 'M', '<--'],
//     ['Enter']
//   ];

//   return (
//     <div className="keyboard">
//       {rows.map((row, rowIndex) => (
//         <div key={rowIndex} className="keyboard-row">
//           {row.map((keyValue) => (
//             <Key key={keyValue} value={keyValue} onClick={onKeyPress} />
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// }

const Keyboard = ({ onKeyPress, keyStatuses }) => {
    const rows = [
      ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
      ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
      ['Enter', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '<--'],
    ];
  
    return (
      <div className='keyboard-area'>
        <div className="keyboard">
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="keyboard-row">
              {row.map((keyValue) => (
                <Key key={keyValue} value={keyValue} onClick={onKeyPress} status={keyStatuses[keyValue]} />
              ))}
            </div>
          ))}
        </div>
      </div>

    );
  }

export default Keyboard;