import Papa from 'papaparse';

class WordData {
  constructor() {
    if (!WordData.instance) {
      this.wordData = new Map();
      WordData.instance = this;
    }

    return WordData.instance;
  }

  async loadCSV() {
    const response = await fetch('../../words.csv');
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder('utf-8');
    const csv = decoder.decode(result.value);
    const results = Papa.parse(csv, { header: true, skipEmptyLines: true });
    const entries = results.data.map(({ date, ...rest }) => [date, rest]);
    this.wordData = new Map(entries);
  }

  getWordData(date) {
    return this.wordData.get(date);
  }
}

const wordDataInstance = new WordData();

export default wordDataInstance;
