// App.js
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import WordRow from './components/WordRow';
import Keyboard from './components/Keyboard';
import HowToPlayModal from './components/HowToPlayModal';
import CongratulationsModal from './components/CongratulationsModal';
import './App.css';
import wordDataInstance from './singleton/WordData';
import { format } from 'date-fns';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [word, setWord] = useState(null);
  const [wordInfo, setWordInfo] = useState(null);
  const [isGameStarted, setGameStarted] = useState(false);
  const [isHowToPlayOpen, setHowToPlayOpen] = useState(false); // Add this new state variable

  useEffect(() => {
    const formattedDate = format(new Date(), 'yyyy-MM-dd');
    wordDataInstance.loadCSV().then(() => {
      console.log(wordDataInstance)
      setWord(wordDataInstance.getWordData(formattedDate).word)
      setWordInfo(wordDataInstance.getWordData(formattedDate))
      setIsLoading(false)
    });
  }, []);

  const [guesses, setGuesses] = useState(new Array(6).fill(null));
  const [currentGuess, setCurrentGuess] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [keyStatuses, setKeyStatuses] = useState({});

  const handleKeyPress = (keyValue) => {
    if (keyValue === '<--') {
      // Handle backspace
      setCurrentGuess(currentGuess.slice(0, -1));
    } else if (keyValue === 'Enter') {
      // Handle enter
      if (!currentGuess.trim()) {
        return;
      }

      if (guesses[guesses.length - 1] === null) {
        let newGuesses = [...guesses];
        newGuesses[currentRowIndex] = currentGuess;
        setGuesses(newGuesses);

        const guess = currentGuess.toUpperCase();
        const newKeyStatuses = { ...keyStatuses };
        for (let i = 0; i < word.length; i++) {
          const letter = word[i];
          if (guess.includes(letter)) {
            if (guess[i] === letter && newKeyStatuses[letter] !== 'green') {
              newKeyStatuses[letter] = 'green';
            } else if (!newKeyStatuses[letter] || newKeyStatuses[letter] !== 'green') {
              newKeyStatuses[letter] = 'yellow';
            }
          }
        }
        for (const letter of guess) {
          if (!word.includes(letter)) {
            newKeyStatuses[letter] = 'grey';
          }
        }
        setKeyStatuses(newKeyStatuses);

        if (currentRowIndex < 5) {
          setCurrentRowIndex(currentRowIndex + 1);
        }

        // Check if the guess is equal to the word
        if (currentGuess === word) {
          // If the guess is correct, open the modal
          setModalOpen(true);
        }

        setCurrentGuess("");

      }
    } else {
      // Handle letter key press
      if (currentGuess.length < word.length) {
        setCurrentGuess(currentGuess + keyValue);
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // or a loading spinner component
  }

  if (!isGameStarted) {
    return (
      <div className="App">
        <div className="center-container">
          <HowToPlayModal isOpen={isHowToPlayOpen} onRequestClose={() => setHowToPlayOpen(false)} />
          <h1 className="qumi-title">Qumi Word of the Day</h1>
          <div className="start-buttons">
            <a href="https://www.buymeacoffee.com/qumiword" target="_blank" rel="noreferrer">
              <button className="play-btn">
                Buy me a coffee ☕
              </button>
            </a>
            <button
              onClick={() => setHowToPlayOpen(true)}
              className="how-to-play-btn">
              How to Play
            </button>
            <button
              onClick={() => setGameStarted(true)}
              className="play-btn">
              Play
            </button>
          </div>
        </div>
      </div>

    );
  }

  return (
    <div className="App">
      <Header />

      <div id="game-area" className="game-area">
        <main>
          {guesses.map((guess, index) => {
            if (guess !== null) {
              return <WordRow key={index} word={word} guess={guess.padEnd(word.length, '_')} />;
            } else if (index === currentRowIndex) {
              return <WordRow key={index} word={word} guess={currentGuess.padEnd(word.length, '_')} displayColor={false} />;
            } else {
              return <WordRow key={index} word={word} guess={new Array(word.length).fill('_').join('')} displayColor={false} />;
            }
          })}

          <CongratulationsModal
            isOpen={isModalOpen}
            wordInfo={wordInfo}
            onRequestClose={() => setModalOpen(false)}
            guesses={guesses}
          />
        </main>
      </div>
      <Keyboard onKeyPress={handleKeyPress} keyStatuses={keyStatuses} />
    </div>
  );
}

export default App;
